import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const DeleteEmployee = ({ Name, selectedUser, getDepartmentData, getEmployeeData, dep }) => {
  const [isdelete, setIsdelete] = useState(false);

  const handleDelete = async (id) => {
    console.log("id", id);
    try {
      const response = await axios.delete(
        `https://hr.tanaghomtech.com/portal/public/api/${dep}/${id}`
      );
      console.log("Deleted ID:", response);
      toast.success("Employee deleted successfully");

      setIsdelete(true);

      await getDepartmentData();
    } catch (error) {
      console.error("Error:", error);
    }
    getEmployeeData();
  };

  // Reset modal state when it is shown
  useEffect(() => {
    const modalElement = document.getElementById("delete");

    const handleModalShown = () => setIsdelete(false);

    modalElement.addEventListener("shown.bs.modal", handleModalShown);

    return () => {
      modalElement.removeEventListener("shown.bs.modal", handleModalShown);
    };
  }, []);

  return (
    <>
      {/* Delete Modal */}
      <div
        className="modal custom-modal fade"
        id="delete"
        role="dialog"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Toaster position="top-right" reverseOrder={false} />
          {!isdelete && (
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>{Name}</h3>
                  <p>Are you sure you want to delete?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <button
                      onClick={() => handleDelete(selectedUser)}
                      className="col-6 btn btn-primary continue-btn"
                      data-bs-dismiss="modal" // Closes the modal
                    >
                      Delete
                    </button>
                    <div className="col-6">
                      <Link
                        to="#"
                        data-bs-dismiss="modal" // Closes the modal
                        className="btn btn-primary cancel-btn"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DeleteEmployee;
